import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { container } from "./image-card.module.css"

const ImageCard = ({ image }) => {
  
  return (
    <div className={container}>
      {image &&
      
      <GatsbyImage
        alt={""}
        image={image.gatsbyImageData}
        loading={"lazy"}
        placeholder="none"
        style={
          // {height: "100%", width: "100%",}
          {
            height: "100%", 
            width: "100%", 
            position: "absolute", 
            left: "0", 
            top: "0",
          }
        }
        imgStyle={
          { objectFit: "contain", padding: "5%"}
        }
      /> }     
    </div>
  )
}
export default ImageCard