import * as React from "react"
import { Link } from "gatsby"
import { headerContainer, categories, mobileCategoriesOpen, mobileCategoriesClosed, filtersOpen, filtersClosed, filterHeader, filterTag } from "./shop-header.module.css"

const ShopHeader = ({newArrivals, lowToHigh, highToLow, currentPath, currentSort }) => {
  const [filterVis, setFilterVis] = React.useState(false)
  const [categoryVis, setCategoryVis] = React.useState(false)

  return (
    <header className={headerContainer}>
      <nav className={categories}>
        <Link style={currentPath=="/shop" ? {background: 'var(--main-color)', color: "var(--base-color"} : {}} to="/shop">All</Link>
        <Link style={currentPath=="/shop/fiction" ? {background: 'var(--main-color)', color: "var(--base-color"} : {}} to="/shop/fiction">Fiction</Link>
        <Link style={currentPath=="/shop/nonfiction" ? {background: 'var(--main-color)', color: "var(--base-color"} : {}} to="/shop/nonfiction">Nonfiction</Link>
        <Link style={currentPath=="/shop/art" ? {background: 'var(--main-color)', color: "var(--base-color"} : {}} to="/shop/art">Art & Photography</Link>
        <Link style={currentPath=="/shop/rare" ? {background: 'var(--main-color)', color: "var(--base-color"} : {}} to="/shop/rare">Rare & Collectibles</Link>
        <Link style={currentPath=="/shop/merch" ? {background: 'var(--main-color)', color: "var(--base-color"} : {}} to="/shop/merch">Merch</Link>
        <Link style={currentPath=="/shop/pickles" ? {background: 'var(--main-color)', color: "var(--base-color"} : {}} to="/shop/pickles">Pickles</Link>
      </nav>

      <nav className={categoryVis ? mobileCategoriesOpen : mobileCategoriesClosed}>
        <div onClick={() => setCategoryVis(!categoryVis)}>Filter By ↓</div>
        {categoryVis && 
        <>
        <Link style={currentPath=="/shop" ? {background: 'var(--main-color)', color: "var(--base-color"} : {}} to="/shop">All</Link>
        <Link style={currentPath=="/shop/fiction" ? {background: 'var(--main-color)', color: "var(--base-color"} : {}} to="/shop/fiction">Fiction</Link>
        <Link style={currentPath=="/shop/nonfiction" ? {background: 'var(--main-color)', color: "var(--base-color"} : {}} to="/shop/nonfiction">Nonfiction</Link>
        <Link style={currentPath=="/shop/art" ? {background: 'var(--main-color)', color: "var(--base-color"} : {}} to="/shop/art">Art</Link>
        <Link style={currentPath=="/shop/rare" ? {background: 'var(--main-color)', color: "var(--base-color"} : {}} to="/shop/rare">Rare</Link>
        <Link style={currentPath=="/shop/merch" ? {background: 'var(--main-color)', color: "var(--base-color"} : {}} to="/shop/merch">Merch</Link>
        <Link style={currentPath=="/shop/pickles" ? {background: 'var(--main-color)', color: "var(--base-color"} : {}} to="/shop/pickles">Pickles</Link>
        </>}
      </nav>
      <nav className={filterVis ? filtersOpen : filtersClosed}>
        <div className={filterHeader} onClick={() => setFilterVis(!filterVis)}>Sort By ↓</div>
        {filterVis &&
        <>
          <div className={filterTag} onClick={newArrivals} style={currentSort==="new" ? {fontWeight: "bold"}:{}}>New Arrivals</div>
          <div className={filterTag} onClick={lowToHigh} style={currentSort==="lowToHigh" ? {fontWeight: "bold"}:{}}>Price:<br />Low to High</div>
          <div className={filterTag} onClick={highToLow} style={currentSort==="highToLow" ? {fontWeight: "bold"}:{}}>Price:<br />High to Low</div>
        </>
        }
      </nav>
    </header>
  )
}
export default ShopHeader

// import * as React from "react"
// import { useEffect, useState } from "react"
// import { link, filters, filterBtn, filterBtnContainer, shopHeaderContainer, shopNav, selected } from './shop-header.module.css'

// const ShopHeader = ({newArrivals, lowToHigh, highToLow, currentPath, currentSort }) => {
//   const [activePath, setActivePath] = useState("")
//   useEffect(() => {
//     setActivePath(currentPath)
//   }, [currentPath])

//   return (
//     <header className={shopHeaderContainer}>
//       <div className={filters}>
//         <p>Sort By:</p>
//         <div className={filterBtnContainer}>
//           <button className={filterBtn} style={currentSort==="new" ? {fontWeight: "bold"} : {}} onClick={newArrivals}>New Arrivals</button>
//           <button className={filterBtn} style={currentSort==="lowToHigh" ? {fontWeight: "bold"} : {}} onClick={lowToHigh}>Price: Low to High</button>
//           <button className={filterBtn} style={currentSort==="highToLow" ? {fontWeight: "bold"} : {}} onClick={highToLow}>Price: High to Low</button>
//         </div>
//       </div>
//       <nav className={shopNav}>
//         <a className={link} style={(activePath==="/shop" || activePath==="/shop/") ? {backgroundColor: "var(--main-color", color: "#fff"} : {}} href="/shop">All</a>
//         <a className={`${link} ${(currentPath==="/shop/all-books/" || currentPath==="/shop/all-books") && selected}`} href="/shop/all-books">All Books</a>
//         <a className={`${link} ${(currentPath==="/shop/used-books/" || currentPath==="/shop/used-books") && selected}`} href="/shop/used-books">Used Books</a>
//         <a className={`${link} ${(currentPath==="/shop/rare/" || currentPath==="/shop/rare") && selected}`} href="/shop/rare">Rare Books</a>
//         <a className={`${link} ${(currentPath==="/shop/merch/" || currentPath==="/shop/merch") && selected}`} href="/shop/merch">Merch</a>
//         <a className={`${link} ${(currentPath==="/shop/pickles/" || currentPath==="/shop/pickles") && selected}`} href="/shop/pickles">Pickles</a>
//       </nav>
//     </header>
//   )
// }

// export default ShopHeader