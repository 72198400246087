import * as React from "react"
import { graphql, Link } from "gatsby"
import { formatPrice } from "../utils/format-price"
import ImageCard from "./image-card"
import { StoreContext } from "../context/store-context"
import {
  productCardStyle,
  productHeadingStyle,
  productDetailsStyle,
  productSubtitleStyle,
  productPrice,
} from "./product-card.module.css"
import UnavailableBadge from "./unavailable-badge"

const ProductCard = ({ product }) => {
  const {
    storefrontId,
    title,
    priceRangeV2,
    handle,
    images: [firstImage],
    metafields,
  } = product

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    priceRangeV2.minVariantPrice.amount
  )

  // Check if product is sold out from the client,
  // so information is always up-to-date
  const { client } = React.useContext(StoreContext)
  const [available, setAvailable] = React.useState(true)
  React.useEffect(() => {
    const checkAvailability = async () => {
      const result = await client.product.fetch(storefrontId)
      setAvailable(result.availableForSale)
    }
    checkAvailability()
  },[])


  return (
    <Link
      className={productCardStyle}
      to={`/products/${handle}`}
      aria-label={`View ${title} product page`}
    >
      {!available && <UnavailableBadge />}

      <ImageCard image={firstImage} />

      <div className={productDetailsStyle}>
        <div>
          <h2 as="h2" className={productHeadingStyle}>
            {title}
           </h2>        
          <div className={productSubtitleStyle}>
            {
            metafields.length !== 0 ? 
              metafields.find(field=>field.key="subtitle").value 
              : 
              "Sweet Pickle Books"
            }
          </div>
        </div>
        <div className={productPrice}>{price}</div>
      </div>
   </Link>
  )
}

export default ProductCard

export const query = graphql`
  fragment ProductCard on ShopifyProduct {
    id
    storefrontId
    title
    handle
    images {
      id
      altText
      gatsbyImageData(width: 910, placeholder: NONE)
    }
    priceRangeV2 {
      minVariantPrice {
        amount
        currencyCode
      }
    }
    metafields {
      key
      value
    }
  }
`

// import * as React from "react"
// import { graphql, Link } from "gatsby"
// import { GatsbyImage } from "gatsby-plugin-image"
// import { getShopifyImage } from "gatsby-source-shopify"
// // import { formatPrice } from "../utils/format-price"
// import {
//   productCardStyle,
//   productHeadingStyle,
//   productImageStyle,
//   productDetailsStyle,
//   productSubtitleStyle,
//   productPrice,
// } from "./product-card.module.css"

// export function ProductCard({ product, eager }) {

//   const {
//     title,
//     priceRangeV2,
//     slug,
//     featuredImage,
//     // images: [firstImage],
//     metafields,
//     // storefrontImages,
//   } = product
//   const price = Math.trunc(priceRangeV2.minVariantPrice.amount)

//   // const defaultImageHeight = 200
//   // const defaultImageWidth = 200
//   // let storefrontImageData = {}
//   // if (storefrontImages) {
//   //   const storefrontImage = storefrontImages.edges[0].node
//   //   try {
//   //     storefrontImageData = getShopifyImage({
//   //       image: storefrontImage,
//   //       layout: "fixed",
//   //       width: defaultImageWidth,
//   //       height: defaultImageHeight,
//   //     })
//   //   } catch (e) {
//   //     console.error(e)
//   //   }
//   // }

//   // const hasImage = firstImage || Object.getOwnPropertyNames(storefrontImageData || {}).length

//   return (
//     <Link
//       className={productCardStyle}
//       to={slug}
//       aria-label={`View ${title} product page`}
//     >
//       <div className={productImageStyle}><img src={featuredImage.originalSrc} alt={featuredImage.altText} /></div>
//       {/* {hasImage
//         ? (
//           <div className={productImageStyle} data-name="product-image-box">
//             <GatsbyImage
//               alt={firstImage?.altText ?? title}
//               image={firstImage?.gatsbyImageData ?? storefrontImageData}
//               loading={eager ? "eager" : "lazy"}
//               layout="fixed"
//               imgStyle={{
//                 objectPosition: "50% 50%",
//               }}
//             />
//           </div>
//         ) : (
//           <div style={{ height: defaultImageHeight, width: defaultImageWidth }} />
//         )
//       } */}
//       <div className={productDetailsStyle}>
//         <div>
//           <h2 as="h2" className={productHeadingStyle}>
//             {title}
//           </h2>        
//           <div className={productSubtitleStyle}>
//             {
//               metafields.length !== 0 ? 
//                 metafields.find(field=>field.key="subtitle").value 
//                 : 
//                 "Sweet Pickle Books"
//             }
//           </div>
//         </div>

//         <div className={productPrice}>${price}</div>
//       </div>
//     </Link>
//   )
// }

// export const query = graphql`
//   fragment ProductCard on ShopifyProduct {
//     id
//     title
//     slug: gatsbyPath(
//       filePath: "/products/{ShopifyProduct.productType}/{ShopifyProduct.handle}"
//     )
//     featuredImage {
//       id
//       altText
//       originalSrc
//     }
//     priceRangeV2 {
//       minVariantPrice {
//         amount
//         currencyCode
//       }
//     }
//     metafields {
//       key
//       value
//     }
//   }
// `
