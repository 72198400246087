import * as React from "react"
import { badgeContainer, badgeText, badgeSans, badgeSerif} from "./unavailable-badge.module.css"

const UnavailableBadge = () => {
  return (
    <div className={badgeContainer}>
      <p className={badgeText}><span className={badgeSans}>Sold</span><br /><span className={badgeSerif}>Out</span></p>
    </div>
  )
}
export default UnavailableBadge